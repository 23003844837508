import Thoth from '../base/thoth';

class MerchantService extends Thoth {
    getMerchant(merchantId) {
        return this.fetch({
            url: `/merchant/${merchantId}`,
        });
    }

    getMerchantEstablishment() {
        return this.fetch({
            url: '/merchants/consult/name',
        });
    }

    getMerchantGroup() {
        return this.fetch({
            url: '/merchants/consult/group',

        });
    }
}

export default new MerchantService();
